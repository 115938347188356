/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import PageWrapper from 'Layouts/PageWrapper';
import SEO from 'Components/SEO';

export default ({ data }) => {
  const laboratory = data.markdownRemark;
  const featuredImage = laboratory.frontmatter.image.childImageSharp.fluid;

  return (
    <PageWrapper>
      <SEO
        description={ laboratory.frontmatter.description }
        title={ laboratory.frontmatter.title }
      />
      <div className="page__hero page__hero--laboratory">
        <h1 className="page__hero__title">{ laboratory.frontmatter.title }</h1>
      </div>
      <div className="laboratory__page-img__wrapper">
        { !!featuredImage && (
          <a className="laboratory__page-img" href={ laboratory.frontmatter.url } target="_blank" rel="noopener noreferrer">
            <Img fluid={ featuredImage } />
          </a>
        ) }
      </div>

      <div className="page__content page__content--laboratory-inner" dangerouslySetInnerHTML={ { __html: laboratory.html } } />
    </PageWrapper>
  );
};

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        position
        description
        image {
          childImageSharp {
            fluid(maxWidth: 730) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
